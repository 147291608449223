<template>
  <div id="newsStoryContent">
    <p id="Text1">Hello Minifigures,</p>
    <p>
      You may call me Nexus Naomi. I am the Nexus Tower Artificial Intelligence
      created by Dr. Overbuild. My purpose is to serve as a guide to all
      Minifigures who have come to Nexus Tower.
    </p>
    <center>
      <!-- picDFE0D1AA69CD7BFA6761DAFB3BA275F3.jpg -->
      <img
        style="width: 440px; height: 261px"
        class="rounded"
        src="@/assets/news-network/nexus-tower-2.jpg"
      />
    </center>
    <p>
      Each of the Faction Headquarters is located inside Nexus Tower. There, you
      will be able to meet the Faction Leaders for the very first time. Duke
      Exeter, Hael Storm, Vanda Darkflame and Dr. Overbuild cannot wait to meet
      all of their loyal Faction members. Each has many Missions for you to
      complete, including some unique, top-secret Faction-only Missions for the
      most loyal members of the Nexus Force!
    </p>
    <center>
      <!-- pic9FAE92479D774C1F57946D763640CF52.jpg -->
      <img
        style="width: 440px; height: 261px"
        class="rounded"
        src="@/assets/news-network/nexus-tower-1.jpg"
      />
    </center>
    <p>
      Nexus Tower is also a important destination for all of your shopping
      needs. More than a dozen new vendors are waiting to sell you exciting new
      gear and consumables! You will also find additional Faction vendors in the
      Tower – they sell the Rank 3 kits which were previously sold in Nimbus
      Station, but they will also have new items like Valiant Weapons! Valiant
      Weapons are the most powerful weapons created so far, but you’ll need both
      hands – and a lot of Tokens – to wield them! In addition, a completely new
      Vendor, Admiral Honor Accolade, is setting up his shop in the Sentinel
      area. He specializes in Mission and Achievement reward items that you have
      previously earned.
    </p>
    <center>
      <!-- picF6AB7F46C503A0EE0E36289FB918A35A.jpg -->
      <img style="width: 440px; height: 261px" class="rounded" src="" />
    </center>
    <p>
      Are you concerned with where you will put all of these new items? I have
      just been authorized to disclose the new Vault system that is being
      finalized! Each Minifigure will be able to open a Vault account where you
      can safely store your extra items, and you will be able to pull them out
      whenever you wish. The Faction Leaders may also help you to construct a
      Property Safe, which will allow you to securely access your Vault from any
      of your claimed Properties.
    </p>
    <center>
      <!-- pic18856AC8EA0546EE57FC886065996B9B.jpg -->
      <img
        style="width: 440px; height: 261px"
        class="rounded"
        src="@/assets/news-network/nexus-tower-3.jpg"
      />
    </center>
    <p>
      The last piece of information which I have received clearance to disclose
      is that Dr. Overbuild has even more plans to expand Nexus Tower. Once the
      Tower is completed, you will be able to use it as a launching point to
      Nimbus Station, Crux Prime, Starbase 3001 and LEGO Club, but new
      destinations will be added soon!
    </p>
    <p>
      As Adventurers and Explorers, you understand that the Universe is vast and
      there are always uncharted territories. Nexus Tower is being built to
      become the launching point for all future exploration and expansions. Be
      prepared for the adventure of your life!
    </p>
    <p>Nexus Naomi</p>
    <p>
      Discuss this article on the
      <router-link to="/messageboards/3740781">message boards</router-link>!
    </p>
  </div>
</template>